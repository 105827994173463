<template>
  <div class="layout layout--public">
    <div class="layout__body">
      <div class="container tnc">
        <div class="row">
          <div class="col-12 mb-3">
            <icon-logo />
          </div>
        </div>
        <div class="row">
          <div class="col-6 tnc-title">Terms and Conditions</div>
          <div class="col-6 tnc-version" v-text="entity.map.version"></div>
        </div>

        <div class="row">
          <div
            id="tnc"
            class="col-12 tnc-body custom-scrollbar"
            v-html="entity.content"
            @scroll="onScroll"
          ></div>
        </div>
        <div class="row">
          <div class="col-12 d-flex justify-content-end">
            <k-button
              @click="agreement"
              :disabled="disabled"
              theme-color="primary"
            >
              Agree
            </k-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import loginRedirectMixin from '@/shared/mixins/loginRedirect';
import { setLucaCookies } from '@/shared/utils/luca';

export default {
  data() {
    return {
      entity: {
        map: {}
      },
      isLoading: false,
      disabled: true
    };
  },
  mixins: [loginRedirectMixin],
  mounted() {
    let vm = this;
    this.getTermAndCondition();
    this.$nextTick(() => {
      const tnc = document.getElementById('tnc');
      tnc.addEventListener('scroll', function () {
        if (
          Math.ceil(tnc.offsetHeight + tnc.scrollTop) >=
          tnc.scrollHeight - 5
        ) {
          vm.disabled = false;
        }
      });
    });
  },
  computed: {
    ...mapGetters(['isAuthenticated'])
  },
  methods: {
    getTermAndCondition() {
      axios
        .post('/tnc', {
          headers: {
            Authorization: `Bearer ${this.$store.state.principal.idToken}`
          }
        })
        .then((response) => {
          this.entity = response.data;
        });
    },
    agreement() {
      this.$store.commit('agreement');
      if (this.isAuthenticated) {
        setLucaCookies(
          this.$store.state.principal.idToken,
          this.$store.state.principal.lucaAccessible
        );
        this.redirectUser();
      }
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.disabled = false;
      }
    }
  }
};
</script>
